import React, { useState } from "react";
import "./App.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FiArrowUpRight } from "react-icons/fi";
import { IoCopySharp } from "react-icons/io5";

// Import images
import Banner from "./images/banner.png";
import Logo from "./images/logo.png";
import WineDrinker from "./images/swine-removebg-preview 3.png";
// import About from "./images/about us.png";
import Innit from "./images/innit.png";
import Swinee from "./images/swiner.png";
import Tokenomics from "./images/tokenomiks.png";
import Socials from "./images/socials.png";
import Features from "./images/features.png";
import About from "./images/about.png";
import Buy from "./images/buy swine.png";
import Partner from "./images/partnership.png";
import Gold from "./images/gold.png";
import BuySwine from "./images/buyswine.png";
import cheers from "./images/swine_cheers-removebg-preview 1.png";
import Sit from "./images/swine__tma-removebg-preview (2) 1.png";
import Sergey from "./images/buy_swine-removebg-preview 1.png";
import Sniper from "./images/buy_swine-removebg-preview 1 (1).png";
import Richpig from "./images/swine_boss-removebg-preview 1.png";
import SitDrink from "./images/swinee-removebg-preview 2.png";
import Hero from "./images/swine-removebg-preview 4.png";
import Filter from "./images/filter ai 2.png";

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const contractAddress = "0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(
      () => {
        alert("Address copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // Prevent body scroll when mobile menu is open
  React.useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  return (
    <div className="App mybg bg-black fredoka-font md:pb-40 md:pt-6 pb-20">
      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 right-0 bg-black text-white flex justify-between items-center md:px-20 px-5 py-4 shadow-md z-50">
        <img src={Logo} alt="RamPay Logo" className="md:w-[50px] w-[30px]" />

        {/* Desktop Menu */}
        <ul className="hidden md:flex md:space-x-10 space-x-4  px-5 py-2 rounded-2xl">
          <li className="text-slate-300 hover:text-white cursor-pointer">
            Home
          </li>
          <li className="text-slate-300 hover:text-white cursor-pointer">
            <a href="https://stake.swine-meme.xyz">Stake</a>
          </li>
          <li className="text-slate-300 hover:text-white cursor-pointer">
            <a href="https://t.me/swine_coin">Socials</a>
          </li>
        </ul>

        <button className="hidden md:block bg-[#BB4938] hover:brightness-150 hover:scale-105 duration-300 px-10 text-white py-2 rounded-2xl">
          <a href="https://star-fleet.io/astra/swap?inputCurrency=AMB&outputCurrency=0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba">
            {" "}
            Buy Now
          </a>
        </button>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? (
              <IoCloseCircleSharp
                size={30}
                className="text-white cursor-pointer"
              />
            ) : (
              <GiHamburgerMenu
                size={30}
                className="text-white cursor-pointer"
              />
            )}
          </button>
        </div>
      </nav>
      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 mt-16 bg-[#422323] flex flex-col justify-center items-center z-50">
          <ul className="flex flex-col space-y-6 text-white text-2xl">
            <li
              className="hover:text-gray-400 cursor-pointer"
              onClick={() => setIsMobileMenuOpen(false)}>
              Home
            </li>
            <li
              className="hover:text-gray-400 cursor-pointer"
              onClick={() => setIsMobileMenuOpen(false)}>
              <a href="https://stake.swine-meme.xyz">Stake</a>
            </li>

            <li
              className="hover:text-gray-400 cursor-pointer"
              onClick={() => setIsMobileMenuOpen(false)}>
              <a href="https://t.me/swine_coin">Socials</a>
            </li>
          </ul>
          <button
            className="mt-10 bg-black text-white px-6 py-3 rounded-full"
            onClick={() => setIsMobileMenuOpen(false)}>
            <a href="https://star-fleet.io/astra/swap?inputCurrency=AMB&outputCurrency=0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba">
              {" "}
              Buy Now
            </a>
          </button>
        </div>
      )}
      {/* Main Content */}
      <section className="relative pt-24">
        <div className="md:flex md:space-x-10 text-white md:justify-between md:text-left md:px-20 md:mt-20 w-[80%] mx-auto mt-14 relative">
          <img
            src={Hero}
            alt="banner"
            className="w-[800px] h-[300px] md:h-[500px]  absolute -top-10 left-0 md:left-[330px]"
          />
          <div className="mt-8 text-center relative z-10">
            <h1 className="md:text-[58px] text-[30px] font-bold md:w-[70%] mx-auto">
              WECLOME TO $SWINE: THE MEMECOIN REVOLUTION
            </h1>
            <p className="md:text-[32px] text-[22px] md:w-[70%] mx-auto">
              HODL, Stake and Earn with the most entertaining token on the
              blockchain
            </p>
            <div className="space-x-8 w-fit mx-auto flex">
              <button className="bg-[#BB4938] text-white md:px-14 md:py-3 px-6 py-2 cursor-pointer hover:brightness-150 hover:scale-105 duration-300 rounded-xl md:mt-10 mt-7 mb-20">
                <a href="https://stake.swine-meme.xyz/"> Stake $Swine</a>
              </button>
              <button className="bg-white text-black md:px-14 md:py-3 px-6 py-2 cursor-pointer hover:brightness-150 hover:scale-105 duration-300 rounded-xl md:mt-10 mt-7 mb-20">
                <a href="https://star-fleet.io/astra/swap?inputCurrency=AMB&outputCurrency=0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba">
                  Buy $Swine
                </a>
              </button>
            </div>
          </div>
        </div>

        <img
          src={Banner}
          alt="banner"
          className="w-full absolute md:-bottom-[100px] -bottom-[30px]"
        />
      </section>

      <div
        className="md:flex md:space-x-10 text-white md:justify-between md:text-left md:px-20 md:mt-20 w-[80%] mx-auto mt-14"
        style={{ backgroundImage: `url(${WineDrinker})` }}>
        <div className="mt-8 text-center">
          <h1 className="md:text-[58px] text-[30px] font-bold  md:w-[70%] mx-auto">
            WHY $SWINE?
          </h1>
          <p className="md:text-[32px] text-[22px] w-[100%] mx-auto">
            $SWINE is the OG meme on AIRDAO. We are a movement—a phenomenon
            where witt, banter, blockchain, and people value creations converge.
            $SWINE is a symbol of unity, value, and innovation. Originally
            emerging from the now-faded Ambrosus, Swiss Wine once stood as a
            beacon of hope for the supply chain industry adoption. Today, we
            revive that spirit, but this time, it’s powered by the energy and
            passion of an old and new community.
          </p>
          <p className="md:text-[32px] text-[22px] w-[100%] mt-4">
            <span className="text-red-600">Catch-phrase:</span> Turn your L's
            into W's
          </p>
        </div>
      </div>
      <div className="md:flex md:space-x-10 text-white md:justify-between md:text-left md:px-20 md:mt-20 w-[80%] mx-auto mt-14">
        <div className="mt-8 text-center">
          <h1 className="md:text-[58px] text-[30px] font-bold  md:w-[70%] mx-auto">
            GET YOUR HANDS ON $SWINE{" "}
          </h1>
          <p className="md:text-[32px] text-[22px] w-[100%] mx-auto">
            To Buy $SWINE, download any EVM Compatible web3 wallet, purchase
            $AMB from an exchange or bridge $AMB and send it to your wallet then
            buy $SWINE on Astra Exchange. You can also buy $SWINE directly
            through our Telegram Bot.
          </p>
          <div className="space-x-8  w-fit mx-auto flex">
            <button className="bg-[#BB4938] hover:brightness-150 hover:scale-105 duration-300 text-white md:px-14 md:py-3 px-6 py-2 cursor-pointer rounded-xl md:mt-10 mt-7 mb-20">
              <a href="https://star-fleet.io/astra/swap?inputCurrency=AMB&outputCurrency=0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba">
                {" "}
                Buy on Astra
              </a>{" "}
            </button>
            <button className="bg-white text-black hover:brightness-150 hover:scale-105 duration-300 md:px-14 md:py-3 px-6 py-2  cursor-pointer rounded-xl md:mt-10 mt-7 mb-20">
              <a href="https://t.me/SWINE_buybot"> Buy on TG</a>{" "}
            </button>
          </div>
        </div>
      </div>
      <section>
        <h1 className="text-white md:text-[60px] text-3xl font-semibold mb-8">
          FEATURES
        </h1>
        <div className="md:grid m:grid-flow-row grid-cols-3 gap-16 flex flex-col px-10 mb-10">
          <div className="bg-white w-fit rounded-xl relative border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer">
            <div className="flex justify-between mb-4">
              <h2 className="font-bold md:text-5xl text-3xl">Buy $SWINE</h2>
              <FiArrowUpRight className="text-2xl font-bold" />{" "}
            </div>
            <p className="md:text-3xl text-left ">
              Tap into the fastest growing meme coin on the blockchain.
            </p>
            <img
              src={BuySwine}
              alt="BuySwine"
              className="md:w-[180px] w-[120px] absolute top-3 right-4"
            />
            <div className="flex justify-between mb-4">
              <h2 className="bg-[#BB4938] rounded-xl px-8 py-2 mt-8 mx-auto hover:brightness-110 duration-300 hover:scale-105 text-white cursor-pointer md:text-2xl">
                <a href="https://star-fleet.io/astra/swap?inputCurrency=AMB&outputCurrency=0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba">
                  Buy $SWINE
                </a>
              </h2>
            </div>
          </div>
          <div className="bg-white w-fit rounded-xl relative border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer ">
            <div className="flex justify-between mb-4">
              <h2 className="font-bold md:text-5xl text-3xl">STAKE</h2>
              <FiArrowUpRight className="text-2xl font-bold" />{" "}
            </div>
            <p className="md:text-3xl text-left ">
              Earn rewards by staking your $SWINE tokens.
            </p>
            <img
              src={cheers}
              alt="BuySwine"
              className="md:w-[240px] w-[160px] absolute top-3 right-4"
            />
            <div className="flex justify-between mb-4">
              <h2 className="bg-[#BB4938] rounded-xl px-8 py-2 mt-8 mx-auto hover:brightness-110 duration-300   hover:scale-105 text-white cursor-pointer md:text-2xl">
                <a href="https://stake.swine-meme.xyz">Stake $SWINE</a>
              </h2>
            </div>
          </div>
          <div className="bg-white w-fit rounded-xl relative border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer ">
            <div className="flex justify-between mb-4">
              <h2 className="font-bold md:text-5xl text-3xl">TG MINI-APP</h2>
              <FiArrowUpRight className="text-2xl font-bold" />{" "}
            </div>
            <p className="md:text-3xl text-left">
              Test your reflexes and earn rewards with our fun Telegram Mini
              App.
            </p>
            <img
              src={Sit}
              alt="BuySwine"
              className="md:w-[220px] w-[160px] absolute top-3 right-4"
            />
            <div className="flex justify-between mb-4">
              <h2 className="bg-slate-600 rounded-xl px-8 py-2 mt-8 mx-auto duration-300  md:text-2xl  text-white ">
                Coming Soon!
              </h2>
            </div>
          </div>
        </div>
        <div className="md:grid grid-flow-row grid-cols-3 flex flex-col gap-16 px-10 mt-20">
          <div className="bg-white w-fit rounded-xl relative  border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer ">
            <div className="flex justify-between mb-4">
              <h2 className="font-bold md:text-5xl text-3xl">Sergey (AI)</h2>
              <FiArrowUpRight className="text-2xl font-bold" />{" "}
            </div>
            <p className="md:text-3xl text-left ">
              Designed to give you a complete edge in the cryto space{" "}
            </p>
            <img
              src={Sergey}
              alt="BuySwine"
              className="md:w-[200px] w-[130px] absolute top-3 right-4"
            />
            <div className="flex justify-between mb-4">
              <h2 className="bg-[#BB4938] rounded-xl px-8 py-2 mt-8 mx-auto hover:brightness-110 duration-300   hover:scale-105 text-white cursor-pointer md:text-2xl">
                <a href="https://t.me/swine_coin">Ask Sergey</a>
              </h2>
            </div>
          </div>
          <div className="bg-white col-span-2 w-full rounded-xl relative border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer ">
            <div className="flex justify-between mb-4">
              <h2 className="font-bold md:text-5xl text-3xl">SNIPER BOT</h2>
              <FiArrowUpRight className="text-2xl font-bold" />{" "}
            </div>
            <p className="md:text-3xl text-left  ">
              Ultimate tool for getting in early on token launches and securing
              high-demand assets. <br />
              PS: Holding 300k swine makes it free to use!{" "}
            </p>
            <img
              src={Sniper}
              alt="BuySwine"
              className="w-[180px] absolute top-3 right-4"
            />

            <div className="flex justify-between mb-4">
              <h2 className="bg-[#BB4938] rounded-xl px-8 py-2 mt-8 mx-auto hover:brightness-110 duration-300   hover:scale-105 text-white cursor-pointer md:text-2xl">
                <a href="https://t.me/SWINE_buybot">Use Bot</a>
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="md:mt-[250px] mt-20 w-[90%] mx-auto">
        <div className="bg-white col-span-2  rounded-xl relative border-2 p-8 border-[#BB4938] hover:brightness-75 duration-300 cursor-pointer md:w-[95%] mx-auto ">
          <img
            src={Richpig}
            alt="BuySwine"
            className="w-[500px] absolute  -left-10 bottom-0"
          />

          <div className=" w-fit mx-auto">
            <p className="md:text-5xl text-2xl w-full mb-4  text-center">
              Token Address
            </p>

            <div
              className="flex items-center space-x-10 border-2 p-6 mb-4 w-full rounded-xl border-black shadow-md shadow-black"
              onClick={copyToClipboard}>
              <p className="md:text-3xl text-[8px] text-left break-words ">
                0xC410F3EB0c0f0E1EFA188D38C366536d59a265ba
              </p>
              <IoCopySharp className="md:text-2xl font-bold" />{" "}
            </div>
          </div>

          <img
            src={SitDrink}
            alt="BuySwine"
            className="w-[300px] absolute hidden md:block -top-[130px] right-0"
          />
          {/* add a table with 6 columns and one row */}
          <div className="block md:hidden">
            {/* Mobile layout: one row of 6 columns using flex or grid */}
            <div className="grid grid-cols-2 gap-2 p-4 rounded-md border-2 border-black">
              <div className="font-bold">Symbol</div>
              <div>$SWINE</div>

              <div className="font-bold">Total Supply</div>
              <div>1,000,000,000 ($SWINE)</div>

              <div className="font-bold">Tax</div>
              <div>0% buy/0% sell</div>

              <div className="font-bold">Airdropped</div>
              <div>100%</div>

              <div className="font-bold">Liquidity</div>
              <div>100% burn</div>

              <div className="font-bold">Max Supply</div>
              <div>1,000,000,000</div>
            </div>
          </div>

          <div className="hidden md:block overflow-x-auto">
            {/* Desktop layout: traditional table */}
            <table className="min-w-full border-collapse">
              <thead>
                <tr>
                  <th className="border-t-2 border-r-2 border-black p-3 pt-6">
                    Symbol
                  </th>
                  <th className="border-t-2 border-r-2 border-black p-3 pt-6">
                    Total Supply
                  </th>
                  <th className="border-t-2 border-r-2 border-black p-3">
                    Tax
                  </th>
                  <th className="border-t-2 border-r-2 border-black p-3 pt-6">
                    Airdropped
                  </th>
                  <th className="border-t-2 border-r-2 border-black p-3 pt-6">
                    Liquidity
                  </th>
                  <th className="border-t-2 border-r-2 border-black p-3 pt-6">
                    Max Supply
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-r-2 border-black p-3 pt-6">$SWINE</td>
                  <td className="border-r-2 border-black p-3 pt-6">
                    1,000,000,000 ($SWINE)
                  </td>
                  <td className="border-r-2 border-black p-3 pt-6">
                    0% buy/0% sell
                  </td>
                  <td className="border-r-2 border-black p-3 pt-6">100%</td>
                  <td className="border-r-2 border-black p-3 pt-6">
                    100% burn
                  </td>
                  <td className="border-r-2 border-black p-3 pt-6">
                    1,000,000,000
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className=" mx-auto md:mt-44 w-[90%] mt-20 relative">
        <img src={Socials} alt="Socials" className="md:w-full" />
        <div className="flex md:space-x-24 space-x-5 bottom-0 ml-3 absolute md:bottom-14 md:left-24">
          <a
            href="https://t.me/swine_coin"
            className="md:w-[300px] md:h-[100px] w-[100px] h-[40px] bg-black opacity-0">
            Telegram
          </a>
          <a
            href="https://x.com/swine_c?s=11"
            className="md:w-[300px] md:h-[100px] w-[100px] h-[40px] bg-slate-500 opacity-0">
            Twitter
          </a>
        </div>
      </section>

      <section className="text-white flex justify-between items-center border rounded-lg mt-20 md:px-16 px-6 md:py-4 py-2 w-[85%] mx-auto">
        <img src={Logo} alt="Socials" className="md:w-[100px] w-[50px]" />
        <h1 className="font-bold md:text-3xl">AI Partner</h1>
        <img src={Filter} alt="Socials" className="md:w-[100px] w-[20%]" />
      </section>
    </div>
  );
}

export default App;
